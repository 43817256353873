<template>
	<div class="grid">
		<div
			class="relative my-auto overflow-x-auto overflow-y-hidden hide-horizontal-scrollbar"
			:class="orderingDisabled ? 'pl-3' : 'pr-2'"
		>
			<WwSideScroller
				:scroller-class="`${scrollerClass} hide-horizontal-scrollbar`"
				item-spacing="space-x-3"
				:gradient-width="64"
				:overscroll-limit="10"
				:fade-visibility="fadeVisibility"
				:class="{ 'mx-1': !orderingDisabled }"
			>
				<button
					v-for="({ label, amount, index }) in pricing"
					:key="index"
					:class="[
						getPriceButtonBorderClasses(index),
						'flex flex-col min-w-24 p-2 px-4 relative text-black group transition-all duration-100 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-300 focus:ring-offset-2',
						{ 'ml-1 xl:ml-[9px]': !orderingDisabled },
						sizesInCart.includes(index) ?
							'bg-green-100 border-green-500 xl:hover:border-red-500 xl:hover:bg-red-100' :
							'hover:border-green-500 bg-white'
					]"
					@click.prevent="handlePriceButtonClick(label, amount, index)"
				>
					<span
						v-if="!orderingDisabled"
						class="absolute top-0 mt-1 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-full -right-[18px]"
					>
						<span class="flex justify-center w-5 h-5 transition-all duration-100 ease-in-out bg-white border border-gray-400 rounded-full shadow xl:group-hover:shadow-lg xl:group-hover:scale-110 focus:outline-none focus:ring-2 focus:ring-green-300 focus:ring-offset-2">
							<PlusIcon
								v-if="!sizesInCart.includes(index)"
								class="m-auto text-gray-500"
							/>
							<MinusIcon
								v-else
								class="m-auto text-black transition-all duration-100 ease-in-out group-hover:text-red-500"
							/>
						</span>
					</span>
					<LoadingSpinner
						v-if="itemBeingAddedToCart == index"
						class="absolute top-0 left-0 w-full h-full p-2 bg-white opacity-75"
					/>
					<span class="font-bold">
						${{ amount }}
					</span>
					<span class="text-sm text-gray-500">
						{{ label }}
					</span>
				</button>
			</WwSideScroller>
		</div>
	</div>
</template>

<script async>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import MinusIcon from '@/components/icons/MinusIcon.vue'
import PlusIcon from '@/components/icons/PlusIcon.vue'
import LoadingSpinner from '@/components/multiUse/LoadingSpinner.vue'
import WwSideScroller from '@/components/UI/WwSideScroller.vue'
import { ADD_TO_CART_MODAL, DELETE_FROM_CART_MODAL } from '@/constants/modal/names.js'
import { ListingCartFragment } from '@/gql/fragments/listing.gql'
import { GetCarts } from '@/gql/queries/carts.gql'
import { logError } from '@/utils/error-handling.js'
import { getPricePerUnit } from '@/utils/pricing.js'

export default {
	components: {
		WwSideScroller,
		LoadingSpinner,
		MinusIcon,
		PlusIcon
	},
	props: {
		product: {
			type: Object,
			required: true
		},
		businessUrl: {
			type: String,
			default: ''
		},
		businessId: {
			type: Number,
			required: true
		},
		indication: {
			type: String,
			default: ''
		},
		orderingDisabled: {
			type: Boolean,
			default: false
		},
		disableQuickAdd: {
			type: Boolean,
			default: false
		},
		fadeVisibility: {
			type: String,
			default: ''
		},
		scrollerClass: {
			type: String,
			default: 'py-2 pr-2'
		}
	},
	data () {
		return {
			sizesInCart: [],
			itemBeingAddedToCart: null
		}
	},
	computed: {
		...mapGetters('cart', [ 'cart' ]),
		...mapGetters('modal', [ 'activeModalId' ]),
		pricing() {
			return getPricePerUnit({
				product: this.product,
				indication: this.indication
			})
		}
	},
	watch: {
		'cart.lastAddedItem'(item, previousItem) {
			if (item === previousItem || !this.cart.lastAddedItem.name) return
			this.showModal(ADD_TO_CART_MODAL)
		},
		async activeModalId(newValue, oldValue) {
			if (!newValue) {
				const cartItemsArray = this.$apollo?.provider?.defaultClient?.readFragment({
					fragment: ListingCartFragment,
					id: `Listing:${this.businessId}`
				})?.cart?.items || []
				if (!cartItemsArray.length && oldValue === ADD_TO_CART_MODAL) {
					await Array.from(this.$apollo.provider.defaultClient.queryManager.queries).map(([ key, value ]) => value)
						.find(query => query.observableQuery?.queryName === 'GetBusinessDetails')
						.observableQuery
						.refetch()
				}
				this.setPriceButtonState()
			}
		}
	},
	mounted() {
		this.setPriceButtonState()
	},
	methods: {
		...mapActions('cart', [
			'addToCart',
			'stageItemToRemove'
		]),
		...mapMutations('modal', [ 'showModal' ]),
		handlePriceButtonClick(label, amount, index) {
			const itemIsAlreadyInCart = this.sizesInCart.includes(index)
			if (itemIsAlreadyInCart) {
				this.handleDeleteFromCart(label, amount, index /*, key */)
			} else {
				this.handleAddToCart(/* label, amount, key, */ index, amount)
			}
		},
		setPriceButtonState() {
			this.sizesInCart = [] // reset array to initial state
			const cartItemsArray = this.$apollo?.provider?.defaultClient?.readFragment({
				fragment: ListingCartFragment,
				id: `Listing:${this.businessId}`
			})?.cart?.items || []
			if (cartItemsArray?.length) {
				// if an item in cart matches this product card id, return array of matching items.
				const matchingItemsArray = cartItemsArray.filter(item => parseInt(item.productId) === parseInt(this.product.id))
				if (matchingItemsArray && matchingItemsArray.length) { // if there are matching items in cart
					matchingItemsArray.forEach(item => { // then add each item's size to the sizesInCart Array.
						if (!this.sizesInCart.includes(item.size)) { // if the size is not already in the array
							this.sizesInCart.push(parseInt(item.size)) // add the size to the array
						}
					})
				} else { // if there are no matching items in cart
					this.sizesInCart = [] // clear the sizesInCart array
				}
			}
		},
		async handleAddToCart(index, amount /* key */) {
			if (this.product.hasOrdering) {
				this.itemBeingAddedToCart = index
				try {
					const response = await this.addToCart({
						listing_id: this.businessId,
						items: [ {
							product_id: this.product?.id,
							size: index,
							indication: this.indication,
							quantity: 1,
							name: this.product.name
						} ]
					})
					const { carts } = this.$apollo.provider.defaultClient.readQuery({
						query: GetCarts
					})
					const cartIndex = carts?.findIndex(cart => parseInt(cart.listing?.id) === parseInt(this.businessId))
					if (cartIndex === -1 || cartIndex === undefined) {
						await Array.from(this.$apollo.provider.defaultClient.queryManager.queries).map(([ key, value ]) => value)
							.find(query => query.observableQuery?.queryName === 'GetCarts')
							.observableQuery
							.refetch()
					} else {
						const itemsCopy = [ ...carts[cartIndex]?.items ]
						itemsCopy.push(
							{
								indication: this.indication,
								name: this.product?.name,
								price: amount,
								productId: this.product?.id,
								quantity: 1,
								size: String(index),
								image: this.product?.image,
								sizeLabel: this.product?.pricingOld?.[0]?.values?.[index]?.label,
								__typename: 'CartItem'
							}
						)
						const newCart = {
							...carts[cartIndex],
							numItems: response?.data?.results?.num_items,
							tax: response?.data?.results?.tax,
							total: response?.data?.results?.total,
							subTotal: response?.data?.results?.subtotal,
							items: itemsCopy
						}
						carts.splice(cartIndex, 1, newCart)
						this.$apollo.provider.defaultClient.writeQuery({
							query: GetCarts,
							variables: {
								listingId: this.businessId
							},
							data: {
								carts: carts
							}
						})
						this.$apollo.provider.defaultClient.writeFragment({
							fragment: ListingCartFragment,
							id: `Listing:${this.businessId}`,
							data: {
								__typename: 'Listing',
								id: this.businessId,
								cart: {
									__typename: 'Cart',
									...newCart
								}
							}
						})
						this.setPriceButtonState()
					}
				} catch (e) {
					logError(e)
				}
				this.itemBeingAddedToCart = null
			}
			if (this.$route.name === 'businessProduct') this.$router.push(this.businessUrl)
		},
		async handleDeleteFromCart(label, amount, index /*, key */) {
			if (this.product.hasOrdering) {
				this.stageItemToRemove(
					{
						listing_id: this.businessId,
						items: [ {
							product_id: this.product.id,
							size: index,
							indication: this.indication,
							quantity: 1,
							name: this.product.name,
							sizeLabel: label,
							price: amount
						} ]
					}
				)
				this.showModal(DELETE_FROM_CART_MODAL)
			}
		},
		getPriceButtonBorderClasses(index) {
			const sizeInCart = this.sizesInCart.includes(index) ? 'border-green-500' : 'border-gray-400'
			if (this.orderingDisabled) {
				const classes = 'outline-none pointer-events-none border-r-2 border-dashed border-gray-300 last-of-type:border-0 '

				return this.pricing?.length === 1
					? 'pointer-events-none'
					: classes
			}
			return `border-dashed border-2 rounded ${sizeInCart}`
		}
	}

}
</script>
