var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid"},[_c('div',{staticClass:"relative my-auto overflow-x-auto overflow-y-hidden hide-horizontal-scrollbar",class:_vm.orderingDisabled ? 'pl-3' : 'pr-2'},[_c('WwSideScroller',{class:{ 'mx-1': !_vm.orderingDisabled },attrs:{"scroller-class":`${_vm.scrollerClass} hide-horizontal-scrollbar`,"item-spacing":"space-x-3","gradient-width":64,"overscroll-limit":10,"fade-visibility":_vm.fadeVisibility}},_vm._l((_vm.pricing),function({ label, amount, index }){return _c('button',{key:index,class:[
					_vm.getPriceButtonBorderClasses(index),
					'flex flex-col min-w-24 p-2 px-4 relative text-black group transition-all duration-100 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-300 focus:ring-offset-2',
					{ 'ml-1 xl:ml-[9px]': !_vm.orderingDisabled },
					_vm.sizesInCart.includes(index) ?
						'bg-green-100 border-green-500 xl:hover:border-red-500 xl:hover:bg-red-100' :
						'hover:border-green-500 bg-white'
				],on:{"click":function($event){$event.preventDefault();return _vm.handlePriceButtonClick(label, amount, index)}}},[(!_vm.orderingDisabled)?_c('span',{staticClass:"absolute top-0 mt-1 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-full -right-[18px]"},[_c('span',{staticClass:"flex justify-center w-5 h-5 transition-all duration-100 ease-in-out bg-white border border-gray-400 rounded-full shadow xl:group-hover:shadow-lg xl:group-hover:scale-110 focus:outline-none focus:ring-2 focus:ring-green-300 focus:ring-offset-2"},[(!_vm.sizesInCart.includes(index))?_c('PlusIcon',{staticClass:"m-auto text-gray-500"}):_c('MinusIcon',{staticClass:"m-auto text-black transition-all duration-100 ease-in-out group-hover:text-red-500"})],1)]):_vm._e(),_vm._v(" "),(_vm.itemBeingAddedToCart == index)?_c('LoadingSpinner',{staticClass:"absolute top-0 left-0 w-full h-full p-2 bg-white opacity-75"}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"font-bold"},[_vm._v("\n\t\t\t\t\t$"+_vm._s(amount)+"\n\t\t\t\t")]),_vm._v(" "),_c('span',{staticClass:"text-sm text-gray-500"},[_vm._v("\n\t\t\t\t\t"+_vm._s(label)+"\n\t\t\t\t")])],1)}),0)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }